import React from "react";
import Logo from "../images/pizzaChefs.png";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="navbar">
      <div className="leftSide">
        <Link to="/">
          {" "}
          <img src={Logo} alt="logo" style={{ marginRight: "10px" }} />
        </Link>
        <Link className="logo" to="/" style={{ marginRight: "20px" }}>
          Tik-Tak
        </Link>
      </div>
      <div className="rightSide">
        <Link to="/">Home</Link>
        <Link to="/burgers">Burgers</Link>
        <Link to="/sandwitches">Sandwitch</Link>
        <Link to="/tacos-et-salades"> Tacos et Salades</Link>
        <Link to="/plats"> Assiettes</Link>
        <Link to="/gourmandises">Gourmandises</Link>
      </div>
    </div>
  );
}

export default Navbar;
