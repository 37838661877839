import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../images/banner.jpg";

function Home() {
  return (
    <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
      <div className="headerContainer">
        <h1>Tik Tak Burger</h1>
        <p>
          Délicieux burgers et plats <br />
          <span style={{ color: "yellow" }}>Tout fait maison</span>
        </p>
        <p>À des prix imbattables !</p>
        <Link to="/burgers">
          <button>Commander maintenant</button>
        </Link>
        <p>Appelez le 09 55 55 14 81</p>
      </div>
    </div>
  );
}

export default Home;
