import React from "react";
import { Sandwitchs } from "../helper/MenuList";

function Menu() {
  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
        Nos Sandwitches
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
        }}
      >
        {Sandwitchs.map((menuItem, key) => {
          return (
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                border: "1px solid #000",
                borderRadius: "10px",
                padding: "15px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  position: "relative",
                  margin: "auto",
                }}
              >
                <img
                  src={menuItem.image}
                  alt=""
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <h3 style={{ textAlign: "center" }}>{menuItem.name}</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center", // Add this

                  alignItems: "center",
                  justifyItems: "center",
                  gap: "20px",
                  width: "100%", // Add this
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #30A7A3", // Teal color for solo
                    backgroundColor: "#E6F7F6", // Lighter shade of teal for background
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <h3 style={{ margin: "0 10px 0 0" }}>solo</h3>
                  <p style={{ fontWeight: "bold", margin: 0 }}>
                    {menuItem.solo} €
                  </p>
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #FF6B6B", // Coral color for menu
                    backgroundColor: "#FFE3E3", // Lighter shade of coral for background
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <h3 style={{ margin: "0 10px 0 0" }}>menu</h3>
                  <p style={{ fontWeight: "bold", margin: 0 }}>
                    {menuItem.menu} €
                  </p>
                </span>
              </div>

              <span
                style={{
                  display: "block",
                  padding: "10px 15px", // Increased padding for better spacing
                  fontStyle: "italic",
                  color: "#555", // Darkened the text a bit for better contrast
                  backgroundColor: "#F9F9F9", // Soft gray background
                  border: "1px solid #E0E0E0", // Subtle border
                  borderRadius: "5px", // Rounded corners
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Slight shadow for depth
                  marginTop: "10px", // Space from the above element
                  textAlign: "center", // Centered text
                }}
              >
                {menuItem.description}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Menu;
