import Cheese from "../images/burgers/cheese.jpg";
import DoubleBurger from "../images/burgers/doubleburger.jpg";
import DuChef from "../images/burgers/duchef.jpg";
import BlueCheese from "../images/burgers/bluecheese.jpg";
import Crunchy from "../images/burgers/crunchy.jpg";
import VeganBurger from "../images/burgers/veganburger.jpg";
import Chiken from "../images/burgers/chicken.jpg";
import Fish from "../images/burgers/fish.jpg";

import Kebab from "../images/sandwitches/kebab.jpeg";
import Escalope from "../images/sandwitches/Escalope.jpeg";
import Tacos from "../images/sandwitches/Tocos.jpeg";
import Incontournable from "../images/sandwitches/incontournable.jpg";
import Phenomene from "../images/sandwitches/Phenomene.jpg";
import Suisse from "../images/sandwitches/suisse.png";

import Cesar from "../images/tacosetsalades/césar.jpg";
import Thon from "../images/tacosetsalades/thon.jpg";
import Trad from "../images/tacosetsalades/trad.jpg";

import AssietteSteak from "../images/plats/assiette-steak.png";
import AssiettesPoulet from "../images/plats/assiette-poulet.png";
import Dorade from "../images/plats/dorade.jpg";
import Brochette from "../images/plats/brochette.jpeg";

import Bouche from "../images/gourmandises/bouche.jpeg";
import Bouteille from "../images/gourmandises/boutilles.jpg";
import CafeThe from "../images/gourmandises/cafeEtThe.jpeg";
import Cannettes from "../images/gourmandises/canettes.jpg";
import MenuEnfant from "../images/gourmandises/enfant.png";
import TiramisuTartDaimEtTatin from "../images/gourmandises/dessers.jpg";

const BurgerList = [
  {
    name: "Cheese Burger",
    image: Cheese,
    solo: 5.9,
    menu: 6.9,
    description:
      "Salade / Tomate / Oignons (confits) / Bacon / Cornichon / Cheddar / Sauce maison.",
  },
  {
    name: "Double Burger",
    image: DoubleBurger,
    solo: 7.9,
    menu: 8.9,
    description:
      "Salade / Tomate / Oigons (confits) / Bacon / Cornichon / Double cheddar / Sauce maison",
  },
  {
    name: "Burger du chef",
    image: DuChef,
    solo: 10.9,
    menu: 11.9,
    description:
      "Viande bouchaire / Escalope panée / Oeuf / Salade / Tomate / Oigons (confits) / Cheddar / Bacon  / Sauce maison ",
  },
  {
    name: "Blue Cheese Burger",
    image: BlueCheese,
    solo: 5.9,
    menu: 6.9,
    description:
      "Salade / Tomate / Oigons (confits) / Blue Cheese / Sauce maison / Bacon  ",
  },
  {
    name: "Crunchy Burger",
    image: Crunchy,
    solo: 8.9,
    menu: 9.9,
    description:
      "Salade / Tomate / Oigons (confits) / Chicken pané / Gal.P.D.T / Sauce maison / Bacon / oeuf ",
  },
  {
    name: "Vegetarien Burger",
    image: VeganBurger,
    solo: 5.9,
    menu: 6.9,
    description:
      "Crousty / Salade / Tomate / Oigons (confits) / Cornichon / Sauce maison ",
  },
  {
    name: "Chicken Pané Burger",
    image: Chiken,
    solo: 5.9,
    menu: 6.9,
    description:
      "Escalope pané / Salade / Tomate / Oigons (confits) / Cheddar / Sauce maison ",
  },
  {
    name: "Fish Burger",
    image: Fish,
    solo: 5.9,
    menu: 6.9,
    description:
      "Poisson pané / Salade / Tomate / Oigons (confits) / Cheddar / Sauce tartare ",
  },
];

const Sandwitchs = [
  {
    name: "Kebab Maison",
    image: Kebab,
    solo: 5.9,
    menu: 6.9,
    description:
      "Le kebab est une délicieuse spécialité culinaire d'origine orientale",
  },
  {
    name: "Escalope (mariné)",
    image: Escalope,
    solo: 6.0,
    menu: 7.0,
    description: "Escalope / Salade / Oignons",
  },
  {
    name: "L'incontournable (viande bouchere)",
    image: Incontournable,
    solo: 7.5,
    menu: 8.5,
    description: "Cheddar / Salade / Bacon / Oignons",
  },
  {
    name: "Phénomène (viande bouchere)",
    image: Phenomene,
    solo: 7.5,
    menu: 8.5,
    description: "Steak Double / Cheddar / Salade / Tomate / Gal.P.D.T",
  },
  {
    name: "Suisse (viande bouchere)",
    image: Suisse,
    solo: 7.5,
    menu: 8.5,
    description: "Oeuf / Escalope Fromage / Sauce Fromagère",
  },
];

const TacosEtSalades = [
  {
    name: "Tacos (viandes x1 x2 x3 x4)",
    image: Tacos,
    solo: "6.9 / 8.9 / 10.9 / 12.9",
    description: "",
  },
  {
    name: "Salade Traditionnelle",
    image: Trad,
    solo: 4.9,
    description:
      "Salade / Tomate / Oignon / Mais / Cornichon / Vinaigrette maison",
  },

  {
    name: "Salade au Thon",
    image: Thon,
    solo: 5.9,
    description:
      "Salade / Tomate / Oignon / cornichon / thon / olives vertes / vinaigrette maison",
  },
  {
    name: "Salade César",
    image: Cesar,
    solo: 5.9,
    description:
      "Salade / Tomate / Oignon / Mais / Tenders / Fromage / Vinaigrette maison",
  },
];

const Assiettes = [
  {
    name: "Brochette",
    image: Brochette,
    solo: 1.5,
    description: "borochettes de boeuf ou de poulet",
  },
  {
    name: "Assiette Steak",
    image: AssietteSteak,
    solo: 10.5,
    description: "",
  },
  {
    name: "Assiette Poulet",
    image: AssiettesPoulet,
    solo: 10.5,
    description: "",
  },
  { name: "Assiette Dorade", image: Dorade, solo: 10.5, description: "" },
];

const Gourmandises = [
  {
    name: "Bouche",
    image: Bouche,
    solo: "1€ la pièce - 2,90€ les 3",
    description:
      "Bouchee camembert/ Stick mozza x3 / Nuggets / Tenders / Samoussa / Nems / Chili cheese / Jalapenos",
  },
  {
    name: "Bouteille 1.25L",
    image: Bouteille,
    solo: "2.90€",
  },
  {
    name: "Café ou Thé",
    image: CafeThe,
    solo: "1.20€",
  },
  {
    name: "Canettes 33cl",
    image: Cannettes,
    solo: "1.20€",
  },
  {
    name: "Menu Enfant",
    image: MenuEnfant,
    solo: "5.00€",
  },
  {
    name: "Tiramisu, Tarte Daim / Tatin",
    image: TiramisuTartDaimEtTatin,
    solo: "3.20€",
  },
];

export { BurgerList, Sandwitchs, Assiettes, TacosEtSalades, Gourmandises };
