import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Burgers from "./pages/Burgers";
import Sandwitch from "./pages/Sandwitchs";
import Plats from "./pages/Plats";
import TacosEtSalades from "./pages/TacosEtSalades";
import Gourmandises from "./pages/Gourmandises";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/burgers" element={<Burgers />} />
          <Route path="/sandwitches" element={<Sandwitch />} />
          <Route path="/tacos-et-salades" element={<TacosEtSalades />}></Route>
          <Route path="/plats" element={<Plats />} />
          <Route path="/gourmandises" element={<Gourmandises />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
